import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Input, Button, message } from 'antd'
import { LockOutlined, LoadingOutlined } from '@ant-design/icons'
import { useAuthorizationContext } from './../../context/authorizationContext/AuthorizationContext'
import { EnumPageUrl } from '@enums'
import { navigate } from 'gatsby'

const ResetPasswordFormContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ResetPasswordForm = styled(Form)`
  width: 30rem;
`

const ResetPasswordFormTitle = styled.h2`
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 1.8rem;
  color: #495057;
`
const ResetPasswordFormSubmitButton = styled(Button)`
  width: 100%;
`

const ResetPasswordInput = styled(Input)`
  height: 3.5rem;
`
const ResetPasswordContent = () => {
  const [form] = Form.useForm()
  const { changePassword } = useAuthorizationContext()

  const [isLoading, showLoading] = useState<boolean>(false)

  const passwordMap = [
    { name: 'oldPassword', placeholder: 'Old Password' },
    { name: 'newPassword', placeholder: 'New Password' },
    { name: 'confirmNewPassword', placeholder: 'Confirm New Password' },
  ]

  const resetPassword = async () => {
    try {
      await form.validateFields()

      const formData = form.getFieldsValue()

      if (formData.newPassword !== formData.confirmNewPassword) {
        message.error('二次新密码输入不一致。')
        return
      }
      showLoading(true)
      const result = await changePassword(formData)
      if (result === 'SUCCESS') {
        navigate(EnumPageUrl.OwnStorePage)
      } else {
        message.error(result)
      }
      showLoading(false)
    } catch (error) {
      if (error) return
    }
  }

  return (
    <ResetPasswordFormContainer>
      <ResetPasswordForm name="resetPassword" form={form}>
        <ResetPasswordFormTitle>重置密码</ResetPasswordFormTitle>
        {passwordMap.map((item) => (
          <Form.Item
            name={item.name}
            rules={[
              { required: true, message: `${item.placeholder} 为必填项！` },
              {
                pattern: /^[\S]+.*[\S]+$/,
                message: '密码格式错误',
              },
            ]}
          >
            <ResetPasswordInput
              prefix={<LockOutlined />}
              type="password"
              placeholder={item.placeholder}
            />
          </Form.Item>
        ))}
        <Form.Item>
          <ResetPasswordFormSubmitButton
            type="primary"
            htmlType="submit"
            icon={isLoading && <LoadingOutlined />}
            onClick={resetPassword}
            disabled={isLoading}
          >
            {isLoading ? '正在重置密码...' : '重置密码'}
          </ResetPasswordFormSubmitButton>
        </Form.Item>
      </ResetPasswordForm>
    </ResetPasswordFormContainer>
  )
}

export default ResetPasswordContent
