import React from 'react'
import Header from '../component/tools/Header'
import { AuthorizationProvider } from '../context/authorizationContext/AuthorizationContext'
import ResetPasswordContent from '../component/tools/ResetPassword'

const ResetPasswordPageContainer = () => {
  return (
    <AuthorizationProvider>
      <Header />
      <ResetPasswordContent />
    </AuthorizationProvider>
  )
}
export default ResetPasswordPageContainer
